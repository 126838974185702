import { Injectable } from '@angular/core';
import { IResourceItem, ApiFactoryService, ExportApi } from 'swx.front-end-lib';

export abstract class Fluid implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    Id: number;
    FluidSeasonId: number;
    ClientFluidName: string;
    Order: number;
    Name: string;
    Dilution: string;
    Type: string;
    WingMaterialType: string;
    FluidTypeNumber: number;
    Airports: Array<number>;
}

export abstract class DispatchAirport implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    Id: number;
    ClientId: number;
    Preferred: boolean;
    IATACode: string;
    ICAOCode: string;
    Name: string;
    IsClear: boolean;
    IsClearSky: boolean;
    IsNightTime: boolean;
    IsFreezing: boolean;
    Type: string;
    WeatherTypeNameResult: string;
    MetarReadingString: string;
    RequestDateTime;
    HotResponse: HotResponse;
    Severity: number;
    StationSimulationId?: number;
    StationGen2Id?: number;
    StationGen3AId?: number;
    StationGen3BId?: number;
    StationGenVId?: number;
    BarrelIcingConditionsPresent: boolean;
    ActiveFrostConditionsPresent: boolean;
    DispatchThroughputGraph: boolean;
    UseNowcast: boolean;
    NowcastData: Array<any>;
    ModeratePrecipitationConditionsPresent: boolean;
    NowcastConfidenceAcceptanceThreshold: number;
    TimeZone: string;

    Type1Hot: HotResponseHot;
    Type2Hot: HotResponseHot;
    Type3Hot: HotResponseHot;
    Type4Hot: HotResponseHot;
}

export class HotResponseHot {
    FluidId: number;
    FluidSeasonId: number|null;
    FluidType: string;
    FluidDilution: string;
    FluidName: string;
    SortableDilution?: number;
    Message: string;
    FluidTypeNumber: number;
    MinHot?: number;
    MaxHot?: number;
    NonHotMessage: string;
    IsGeneric: boolean;
    IsComposite: boolean;
    HotConditionId?: number;
    HotConditionRank?: number;
    HotConditionColor: string;
    HotConditionIcon: string;
    HotConditionName: string;
    
    ReferenceMetarMaxHot: number;
    ReferenceMetarMinHot: number;
    ReferenceMetarMessage: string;
}

export class HotResponse {
    DateTime: string;
    WeatherType: string;
    WeatherTypeCode: string;
    WeatherReport: string;
    WeatherTypeName: string;
    Message: string;
    Type: string;
    MetarUsageReason: string;
    Temperature?: number;
    HotConditionId?: number;
    HotConditionRank?: number;
    HotConditionColor: string;
    HotConditionIcon: string;
    HotConditionName: string;
    FluidMessage: string;
    ForecastMessage: string;
    HoldoverTaxiTimeMessage: string;
    HoldoverTaxiTimeLower?: number;
    HoldoverTaxiTimeUpper?: number;

    HotResponseHots: Array<HotResponseHot>;
}

export abstract class ClientHotCondition implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    Id: number;
    Name: string;
    Color: string;
    Rank: number;
    Icon: string;
    ShowAlertWhenReached: boolean;
}

export class MobileClient {
    ShowOnlyMinHot: boolean;
    OatBelowFluidLoutError: string;
    MobileOatBelowFluidLoutError: string;
    FluidMetarSpecNotFoundError: string;
    MobileFluidMetarSpecNotFoundError: string;
    MetarEmptyWeatherCodeError: string;
    MobileMetarEmptyWeatherCodeError: string;
    MobileAirportError: string;
    MobileDefaultHotText: string;
    MobileSystemError: string;
    MobileShowMetarWeatherDescription: boolean;
    ClientFluids: Array<MobileClientFluid>;
}

export class MobileWeatherInfo {
    MetarWeatherTypeId?: number;
    Name: string;
    Order: number;
    SnowVisibilityMenu: SnowVisibilitiesInfo;
    Items?: Array<MobileWeatherInfo>;
}

export class SnowVisibilitiesInfo {
    SelectedIntensityTitle: string;
    ShowSnowVisibilityDocument: boolean;
    ShowSnowVisibilityText: boolean;
    PortalIntensities?: SnowVisibilitiesMenuIntensity[];
    TemperatureOptions: SnowVisibilitiesMenuTemperatureOption[];
}

export class SnowVisibilitiesMenuIntensity {
    Name: string;
    MetarWeatherType: number;
}

export class SnowVisibilitiesMenuTemperatureOption {
    Name: string;
    MaxTempC: number;
    DayNightOptions: SnowVisibilitiesMenuDayNightOption[];
}

export class SnowVisibilitiesMenuDayNightOption {
    Name: string;
    Values: SnowVisibilitiesMenuValue[];
}

export class SnowVisibilitiesMenuValue {
    Name: string;
    Miles: string;
    Meters: string;
    MetarWeatherType: number;
}

export class MetarMessageMapping {
    MetarWeatherType: number[];
    FluidType: string[];
    MaxTemp: number;
    Message: string;
    MobileMessage: string;
    ShowHotAndMessage: boolean;
    EquipmentTypeId?: number[];
}

export class FluidMetarSpec {
    FluidId: number;
    FluidVersionId: number;
    MetarWeatherTypeId: number;
    MinTemp: number;
    MaxTemp: number;
    MinHot: number;
    MaxHot: number;
    Notes: string;
}

export class MobileClientFluid {
	FluidId: number;
    FluidSeasonId: number;
	FluidVersionId: number;
    Name: string;
    Type: string;
    Dilution: string;
    Order: number;
    Cautions: string;
}

export abstract class ThroughputGraphSample implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    From: Date;
    To: Date;
    PushBackToTaxiInAverage?: number;
    TaxiInToIceManAcceptAverage?: number;
    DeicingTimeAverage?: number;
    IcehouseAcceptOutToTaxiOutAverage?: number;
    TaxiOutToWheelsUpAverage?: number;
}

export abstract class PortalMobileClientConfiguration implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    MobileClient: MobileClient;
    FluidSpecs: Array<FluidMetarSpec>;
    Weathers: Array<MobileWeatherInfo>;
    MessageMappings: Array<MetarMessageMapping>;
}

export abstract class FedExShift implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    Id: number;
    Discipline: string;
    Name: string;
    StartsAt: string;
    Desk: string;
    Flights: FedExFlight[]
    LastUpdatedAt: string;
    Template: string; // not mapped to backend, FDX wants D, M, S, and SHIFT shifts under the NOC "template", there's also a shift called "NOC"
}

export class FedExFlight {
    Id: number;
    FlightNumber: string;
    FlightDate: string;
    Leg: number;
    TailNumber: string;
    ScheduledOut: string;
    OriginAirportId: number;
    DestinationAirportId: number;
}

@Injectable({ providedIn: 'root' })
export class ApiService extends ExportApi {
    constructor(
        private apiFactory: ApiFactoryService 
    ) {
        super();
        
        this.apiFactory.minimumMinutesForOfflineNotification = 5;
    }
    
    DashboardChartType = this.apiFactory.enumResourceFactory('/DashboardChartType');
    DashboardChartPeriod = this.apiFactory.enumResourceFactory('/DashboardChartPeriod');
    EngineCoverAlertType = this.apiFactory.enumResourceFactory('/EngineCoverAlertType');

    Client = this.apiFactory.resourceFactory<any>('/Client');
    ClientAirport = this.apiFactory.resourceFactory<any>('/ClientAirport');
    ClientPortalHot = this.apiFactory.resourceFactory<any>('/ClientPortalHot');
    ClientPortalHotHistory = this.apiFactory.resourceFactory<any>('/ClientPortalHotHistory');
    ClientPortalHotGraphHot = this.apiFactory.resourceFactory<any>('/ClientPortalHotGraphHot');
    ClientPortalHotRequest = this.apiFactory.resourceFactory<any>('/ClientPortalHotRequest');
    ClientHotCondition = this.apiFactory.resourceFactory<ClientHotCondition>('/ClientHotCondition');
    Dashboard = this.apiFactory.resourceFactory<any>('/Dashboard');
    DashboardExport = this.apiFactory.resourceFactory<any>('/DashboardExport');
    PortalUser = this.apiFactory.resourceFactory<any>('/PortalUser');
    PasswordResetToken = this.apiFactory.resourceFactory<any>('/PasswordResetToken');
    ResetPassword = this.apiFactory.resourceFactory<any>('/ResetPassword');
    UserConfirmation = this.apiFactory.resourceFactory<any>('/UserConfirmation');
    User = this.apiFactory.resourceFactory<any>('/User');
    DispatchAirport = this.apiFactory.resourceFactory<DispatchAirport>('/DispatchAirport');
    DispatchAirportProfile = this.apiFactory.resourceFactory<any>('/DispatchAirportProfile');
    PortalUserDispatchAirportConfiguration = this.apiFactory.resourceFactory<any>('/PortalUserDispatchAirportConfiguration');
    PortalMobileClient = this.apiFactory.resourceFactory<PortalMobileClientConfiguration>('/PortalMobileClient');
    Fluid = this.apiFactory.resourceFactory<Fluid>('/Fluid');
    SamlLoginRequest = this.apiFactory.resourceFactory<any>('/Saml/LoginRequest');
    SamlLogoutRequest = this.apiFactory.resourceFactory<any>('/Saml/LogoutRequest');
    SamlLoginResponse = this.apiFactory.resourceFactory<any>('/Saml/LoginResponse');
    ClientPortalDocument = this.apiFactory.resourceFactory<any>('/ClientPortalDocument');
    TableauTrustedAuthenticationToken = this.apiFactory.resourceFactory<any>('/TableauTrustedAuthenticationToken');
    Station = this.apiFactory.resourceFactory<any>('/Station');
    SensorSelectMatrix = this.apiFactory.resourceFactory<any>('/SensorSelectMatrix');
    WeatherProfile = this.apiFactory.resourceFactory<any>('/WeatherProfile');
    WeatherProfileExport = this.apiFactory.resourceFactory<any>('/WeatherProfileExport');
    ThroughputGraphSample = this.apiFactory.resourceFactory<ThroughputGraphSample>('/ThroughputGraphSample');
    DeIcingPortalFlight = this.apiFactory.resourceFactory<any>('/DeIcingPortalFlight');
    EngineCoverAlert = this.apiFactory.resourceFactory<any>('/EngineCoverAlert');
    ExcelExportTask = this.apiFactory.resourceFactory<any>('/ExcelExportTask');
    ExcelExportResult = this.apiFactory.resourceFactory<any>('/ExcelExportResult');
    Lwe12Graph = this.apiFactory.resourceFactory<any>('/Lwe12Graph');
    MobileAppRelease = this.apiFactory.resourceFactory<any>('/MobileAppRelease');
    MobileAppReleaseBuildUrl = this.apiFactory.resourceFactory<any>('/MobileAppReleaseBuildUrl');
    MobileAppReleaseXArchiveUrl = this.apiFactory.resourceFactory<any>('/MobileAppReleaseXArchiveUrl');
    Change = this.apiFactory.resourceFactory('/Change'); // Dummy
    ChangeExport = this.apiFactory.resourceFactory('/ChangeExport'); // Dummy
    ChangeAlert = this.apiFactory.resourceFactory('/ChangeAlert'); // Dummy
    FedexShift = this.apiFactory.resourceFactory<FedExShift>('/FedExShift');
}
