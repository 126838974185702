<div style="display: flex; flex-direction: column; height: 100%;">
    <div class="toolbar">
        <search-box (search)="filterDesks($event)"></search-box>
    </div>
    <div class="toolbar">
        @for(deskGroup of deskGroups; track deskGroup.name) {
            <a class="tab"
               [ngClass]="{active: deskGroup.name === selectedGroup.name}"
               (click)="selectDeskGroup(deskGroup)">
                {{deskGroup.name}}
            </a>
        }
    </div>
    <div style="flex: 1; overflow-y: scroll;" class="container">
        <cdk-accordion>
            @for(discipline of selectedGroup?.disciplines; track discipline.name) {
                <cdk-accordion-item #accordionItem="cdkAccordionItem">
                    <h3 class="discipline"
                    (click)="accordionItem.toggle()"
                        [ngClass]="{active: accordionItem.expanded}">
                        <span [ngClass]="{'mdi': true, 'mdi-chevron-down': accordionItem.expanded, 'mdi-chevron-right': !accordionItem.expanded}"></span>
                        {{discipline.name}}
                    </h3>
                    @if(accordionItem.expanded) {
                        <ul class="desks">
                            @for(shift of discipline.shifts; track shift.name) {
                                @if (shift.name !== selectedGroup.name) {
                                    <h4>{{getLongShiftName(shift.name)}}</h4>
                                }
                                @for(desk of filteredDesks(shift.desks); track desk.Id) {
                                    <li>
                                        <label (click)="toggleShift($event, desk)">
                                            <input type="checkbox" [checked]="isSelected(desk)"/>
                                            {{desk.Desk}}
                                        </label>
                                    </li>
                                }
                            }
                        </ul> 
                    }
                </cdk-accordion-item>
            }
        </cdk-accordion>
    </div>
    <hr />
    <div class="buttons">
        <button class="btn" (click)="cancelSelection()"><span class="mdi mdi-close">{{'Cancel'|translate}}</span></button>
        <button class="btn" (click)="confirmSelection()"><span class="mdi mdi-check"></span> {{'Confirm'|translate}}</button>
    </div>
</div>

